<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="mini" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="initData()">
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="客户信息:" prop="cust_id">
                <el-select filterable v-model="searchForm.cust_id" placeholder="请选择客户信息" size="small" clearable>
                  <el-option v-for="item in custOptionList" :key="item.cust_id" :label="item.cust_name" :value="item.cust_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="收款单状态:" prop="hegi_situ">
                <el-select filterable v-model="searchForm.hegi_situ" placeholder="请选择收款单状态" size="small" clearable>
                  <el-option v-for="item in situList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item> </el-col
            ><el-col :md="8">
              <el-form-item label="登记单号:" prop="hegi_no">
                <el-input size="small" v-model.trim="searchForm.hegi_no" clearable placeholder="请填写登记单号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="8">
              <el-form-item label="公司抬头:" prop="cptt_aid">
                <el-select filterable v-model="searchForm.cptt_aid" placeholder="请选择公司抬头" size="small" clearable>
                  <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="收款银行:" prop="cptt_bank_name">
                <el-input size="small" v-model.trim="searchForm.cptt_bank_name" clearable placeholder="请填写收款银行"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="部门:" prop="status">
                <el-select filterable v-model="searchForm.dept_id" placeholder="请选择部门" size="small" clearable>
                  <el-option v-for="item in deptList" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="14">
              <el-form-item label="收款日期:" prop="timeValue">
                <div class="block">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getHegisNow()" class="vg_ml_16">查询 </el-button>
                <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addHoll">生成收款单</el-button>
        <el-button type="success" size="small">生成预收款单</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="登记单号" prop="hegi_no" />
            <el-table-column label="客户信息" prop="cust_abbr" />
            <el-table-column label="收款日期" prop="hegi_date">
              <template slot-scope="scope">
                <span v-if="scope.row.hegi_date">
                  {{ scope.row.hegi_date | formatDate1 }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="收款单状态" prop="hegi_situ" :formatter="formaSitu" />
            <el-table-column label="收款金额" prop="hegi_total" />
            <el-table-column label="币种" prop="cust_currency" />
            <el-table-column label="汇率" prop="tran_usdrate" />
            <el-table-column label="已领用金额" prop="hegi_claied" />
            <el-table-column label="未领用金额" prop="hegi_unclai" />
            <el-table-column label="收款银行" prop="cptt_bank_name" />
            <el-table-column label="公司抬头" prop="cptt_aname" show-overflow-tooltip />
            <el-table-column label="部门" prop="dept_name" />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { hegiAPI } from '@api/modules/hegi';
import { hollAPI } from '@api/modules/holl';
import { stffAPI } from '@/api/modules/staff';
import { cpttAPI } from '@api/modules/comptitle';
import { custAPI } from '@/api/modules/cust';
import helper from '@assets/js/helper.js';
import { deptAPI } from '@api/modules/department';
import pubPagination from '@/components/common/pubPagination';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'HegiList',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        cust_id: null,
        hegi_no: null,
        cptt_aid: null,
        dept_id: null,
        hegi_situ: null,
        cptt_bank_name: null,
        stff_id: null,
        status: null,
        timeValue: [],
        timeValue1: []
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      situList: [
        { id: 0, label: '未生成' },
        { id: 1, label: '已生成' }
      ],
      stffUserList: [],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ],
      cpttList: [],
      custOptionList: [],
      deptList: []
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/hegi_edit') {
        this.initData();
      }
    }
  },
  filters: {
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    initData() {
      this.getHegisList();
      this.getStffUser();
      this.getCptt();
      this.initCust();
      this.getDept();
    },
    getHegisList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(hegiAPI.getHgins, {
        cptt_bank_name: this.searchForm.cptt_bank_name,
        hegi_situ: this.searchForm.hegi_situ,
        hegi_no: this.searchForm.hegi_no,
        dept_id: this.searchForm.dept_id,
        cptt_aid: this.searchForm.cptt_aid,
        cust_id: this.searchForm.cust_id,
        page_no: this.currentPage,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取客户
    initCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取人员信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        cust_id: null,
        hegi_no: null,
        cptt_aid: null,
        dept_id: null,
        hegi_situ: null,
        cptt_bank_name: null,
        stff_id: null,
        status: null,
        timeValue: [],
        timeValue1: []
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getHegisNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 生成收款单
    addHoll() {
      if (this.multiSelection.length !== 1) {
        return this.$message.warning('只能选择一条数据');
      }
      post(hollAPI.addHoll, { hegi_id: this.multiSelection[0].hegi_id })
        .then(res => {
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: '生成成功', type: tp });
            this.jump('/holl_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: 145,
                  form_id: res.data.data.holl_id
                })
              )
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 回显收票类型
    formaSitu(row) {
      if (row.hegi_situ === 0) {
        return '未生成';
      } else if (row.hegi_situ === 1) {
        return '已生成';
      }
    },
    formaType(row) {
      if (row.hegi_type === 1) {
        return '电汇';
      } else if (row.hegi_type === 2) {
        return '现金';
      } else if (row.hegi_type === 3) {
        return '信用证';
      } else if (row.hegi_type === 4) {
        return '承兑';
      }
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getHegisList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
